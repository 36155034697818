<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Pastas do drive</h3>
        </div>
        <div class="card-body">
          <b-table
            :fields="['nome', 'link', 'created_at', 'Acoes']"
            :items="lista_config_pasta_drive"
            :per-page="perPage"
            :current-page="currentPage"
            id="configPastaDrive-table"
            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            show-empty
            empty-text="Nenhum registro encontrado!"
          >
            <template #head(Acoes)><span></span></template>
            <template #head(created_at)><span>Criação</span></template>
            <template #cell(created_at)="{ item }">
                <span>{{ item.created_at | formatDate }}</span>
            </template>


            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <button
                  v-show="lista_permissoes_filial.u_ConfigPastaDrive"
                  @click="atualizar(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Editar registro"
                >
                  <i class="far fa-edit text-primary"></i>
                </button>

                <button
                  v-show="lista_permissoes_filial.lock_ConfigPastaDrive"
                  @click="confirm(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Bloquear/Desbloquear registro"
                >
                  <i class="fas fa-unlock-alt text-danger"></i>
                </button>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista_config_pasta_drive.length"
            :per-page="perPage"
            aria-controls="aviso-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import dateFormat from "@/core/mixins/dateFormat";

export default {
mixins: [dateFormat],
  data() {
    return {
      currentPage: 1,
      perPage: 10,
    };
  },
  mounted() {
       this.$store.dispatch(SET_BREADCRUMB, [{title: "Configuração Pasta Drive"}]);
  },
  created() {
    this.listar_config_pasta_drive();
  },
  computed: {
    lista_config_pasta_drive() {
      return this.$store.state.configPastaDrive.lista_config_pasta_drive;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.configPastaDrive.mensagem_alert;
    },
  },
  methods: {
    async listar_config_pasta_drive() {
      await this.$store
        .dispatch("configPastaDrive/listar_config_pasta_drive")
        .finally(() => {
          this.$store.dispatch("configEmpresa/atualizar", "");
        });
    },
    atualizar(value) {
      this.$router.push({ name: "createConfigPastaDrive" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara esta Sala no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch(
        "configPastaDrive/delete_config_pasta_drive",
        value
      );
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>