// import {format} from 'date-fns'
// import {ptBR} from 'date-fns/locale'
import moment from 'moment'


export default {
    filters: {
        // formatDBDateToLocale(value) {
        //     if (value.length > 10) {
        //         value = value.substr(0, 10)
        //     }

        //     if (!value) {
        //         return '--'
        //     }

        //     let [y, m, d] = value.split('-')
        //     return format(
        //         new Date(y, m - 1, d),
        //         'PP',
        //         {locale: ptBR}
        //     )
        // },
        formatDate (date) {
          return moment(date, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm')
        },
        formatEnToBr (date) {
            return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          },
          horaEnToBr (date) {
            return moment(date, 'H:mm').format('H:mm')
          }
    }
}